import './polyfills';

import { contactSubmit } from './contact.js';
import { unitFilters, facilityTabs, facilityReviews, facilityCaro, facilityModalCaro, facilityCaroModal, acquisitionCaro, facilityRatesTable, ratesModal, sizeModal, driveUpModal, featuresToggle, sectionToggle, faqsToggle, viewMore } from './facility.js';
import { initMobileNav } from './header';
import { initMap } from './map';
import { maps } from './facility_map.js';
import { initReviewsCaro } from './reviews';

initMobileNav();


if (document.getElementById('contact_form')) {
	contactSubmit({
		required: ['name', 'phone', 'email', 'facility', 'message']
	});
}

if (document.getElementById('tip_list_wrap')) {
	document.getElementById('tip_list_wrap').addEventListener('click', function(e) {
		if(e.target && e.target.nodeName === "H2") {
			let el = e.target.parentElement;
			if(el.classList.contains('active')) {
				el.classList.toggle('active');
			}
			else {
				el.classList.add('active');
			}
		}
	});
}

if (document.querySelector('#reviews_container')) {
	initReviewsCaro();
}

if (document.querySelector('#map')) {
	initMap();
}

/* eslint-disable no-undef */
if(document.getElementById('index_search_form')){
	searchHelper().bindSearchEvent(document.querySelector('#index_search_form'));
}

maps.init();

unitFilters.init();
facilityTabs.init();
facilityReviews.init();
facilityCaro.init();
facilityModalCaro.init();
facilityCaroModal.init();
acquisitionCaro.init();
facilityRatesTable.init();
ratesModal.init();
sizeModal.init();
driveUpModal.init();
sectionToggle.init();
faqsToggle.init();

if(document.getElementById('features_wrapper')){
	featuresToggle.init();
}

if(document.getElementById('view_more')){
	viewMore.init();
}